import { Box, Button, Grid, Stack, styled, Typography } from '@mui/material'
import { useState } from 'react'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { useGetCreditsWageredHourlyTrends } from 'hooks/api/Reports/useGetCreditsWageredHourlyTrends'
import { CreditsWageredHourlyTrendsChart } from './CreditsWageredHourlyTrendsChart'
import { useSetLe } from 'stores/useSetLe'
import { GamingReportsHeader } from '../GamingReportsHeader'
import { DatePicker } from '@mui/x-date-pickers'
import { colorPrimitives, Colors } from 'components/Theme'
import { useReportDateRangeState } from 'stores/useReportDateRange'
import { dateDifferenceInDays } from 'utils/util'

const StyledDatePicker = styled(DatePicker)({
  '& .MuiFormLabel-root': {
    fontSize: '1rem',
    fontWeight: '100',
    color: Colors.muiLabels.placeholder,
  },
  '& .Mui-focused': {
    color: !Colors.muiLabels.placeholder,
  },
})

export const CreditsWageredHourlyTrendsPage = () => {
  const { passedLe } = useSetLe()
  const { startDate, endDate, setStartDate, setEndDate } =
    useReportDateRangeState()
  const [dateValidationMessage, setDateValidationMessage] = useState('')

  const reportQuery = useGetCreditsWageredHourlyTrends({
    licensedEstablishmentId: Number(passedLe?.id),
    startDate,
    endDate,
  })

  const [displayType, setDisplayType] = useState<'$' | '%'>('$')

  const buttonStyle = (type: '$' | '%') => ({
    maxWidth: '40px',
    maxHeight: '40px',
    minWidth: '40px',
    minHeight: '40px',
    fontSize: '1.8rem',
    backgroundColor: displayType === type ? '#CC2027' : '#e0e0e0',
    color: displayType === type ? 'white' : 'black',
  })

  const SetStartDate = (dateValue: any) => {
    setDateValidationMessage('')
    if (dateDifferenceInDays(dateValue, endDate) > 28) {
      dateValue = startDate
      setDateValidationMessage(
        'Start Date and End Date must be within a 4-week max'
      )
    }

    setStartDate(dateValue)
  }

  const SetEndDate = (dateValue: any) => {
    setDateValidationMessage('')
    if (dateDifferenceInDays(startDate, dateValue) > 28) {
      dateValue = endDate
      setDateValidationMessage(
        'Start Date and End Date must be within a 4-week max'
      )
    }

    setEndDate(dateValue)
  }

  return (
    <GamingReportsHeader currentTab={'3'}>
      <Stack spacing={4}>
        <Grid container spacing={1} alignItems="center">
          <Box className="py-1 flex flex-row gap-4 xs:justify-between">
            <StyledDatePicker
              disableFuture
              onChange={(date: any) => {
                SetStartDate(date)
              }}
              label="Start Date"
              value={startDate}
            />
            <StyledDatePicker
              onChange={(date: any) => {
                SetEndDate(date)
              }}
              label="End Date"
              value={endDate}
            />
            <Button
              variant="contained"
              onClick={() => setDisplayType('$')}
              style={buttonStyle('$')}
              title="Total Credits Wagered"
            >
              $
            </Button>
            <Button
              variant="contained"
              onClick={() => setDisplayType('%')}
              style={buttonStyle('%')}
              title="Percentage of Total Credits Wagered"
            >
              %
            </Button>
          </Box>
        </Grid>
        <Typography variant="body-3" color={colorPrimitives.redGaming}>
          {dateValidationMessage}
        </Typography>
        {reportQuery.isPending && reportQuery.isFetching && (
          <ActivityIndicator />
        )}
        {reportQuery.isError && <ErrorIndicator />}
        {reportQuery.data &&
          (reportQuery.data.metrics.length === 0 ? (
            <Typography variant="h4"></Typography>
          ) : (
            <CreditsWageredHourlyTrendsChart
              data={reportQuery.data.metrics}
              displayType={displayType}
              endDate={endDate}
            />
          ))}
      </Stack>
    </GamingReportsHeader>
  )
}
