import { create } from 'zustand'

interface UseReportDateRangeState {
  startDate: Date
  setStartDate: (x: Date) => void
  endDate: Date
  setEndDate: (x: Date) => void
}

const getDefaultStartDate = () => {
  const date = new Date()
  date.setDate(date.getDate() - 28)
  date.setHours(0, 0, 0, 0) // set to midnight 4 weeks ago
  return date
}

const getDefaultEndDate = () => {
  const date = new Date()
  date.setHours(23, 59, 59, 999)
  return date
}

export const useReportDateRangeState = create<UseReportDateRangeState>(
  (set) => ({
    startDate: getDefaultStartDate(),
    setStartDate(x) {
      set({ startDate: x })
    },
    endDate: getDefaultEndDate(),
    setEndDate(x) {
      set({ endDate: x })
    },
  })
)
