import { Stack, Typography } from '@mui/material'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { format } from 'date-fns'
import { useGetVgtTrends } from 'hooks/api/Reports/useGetVgtTrends'
import { VgtTrendsChart } from './VgtTrendsChart'
import { useSetLe } from 'stores/useSetLe'
import { GamingReportsHeader } from '../GamingReportsHeader'
import { useState } from 'react'
import { WeekRangeSelect } from 'components/WeekRangeSelect'

export const VgtTrendsPage = () => {
  const { passedLe } = useSetLe()
  const getDateOffset = (days: number) => {
    const date = new Date()
    date.setDate(date.getDate() + days)
    return date
  }
  const [numDays, setNumDays] = useState<string>('56')
  const [startDate, setStartDate] = useState<Date>(getDateOffset(-56))
  const [endDate] = useState<Date>(getDateOffset(0))

  const reportQuery = useGetVgtTrends({
    licensedEstablishmentId: Number(passedLe?.id),
    startDate,
    endDate,
  })

  const formattedStartDate = format(startDate, 'MM/dd/yyyy')
  const formattedEndDate = format(endDate, 'MM/dd/yyyy')

  interface RawDataItem {
    amount: number
    vgtNumber: string
    date: string
    name: string
    tagNumber: number
  }

  interface TransformedDataItem {
    date: string
    [key: string]: { amount: number; name: string; tagNumber: number } | string
  }

  const transformData = (rawData: RawDataItem[]): TransformedDataItem[] => {
    const groupedByDate: Record<
      string,
      Record<string, { amount: number; name: string; tagNumber: number }>
    > = {}

    rawData.forEach((item) => {
      const formattedDate = format(new Date(item.date), 'yyyy-MM-dd')
      if (!groupedByDate[formattedDate]) {
        groupedByDate[formattedDate] = {}
      }
      groupedByDate[formattedDate][item.vgtNumber] = {
        amount: item.amount,
        name: item.name,
        tagNumber: item.tagNumber,
      }
    })

    return Object.keys(groupedByDate).map((date) => ({
      date,
      ...groupedByDate[date],
    }))
  }

  return (
    <GamingReportsHeader currentTab={'6'}>
      <WeekRangeSelect
        label={`Weeks of: ${formattedStartDate} - ${formattedEndDate}`}
        numDays={numDays}
        setStartDate={setStartDate}
        dateOffset={getDateOffset}
        setNumDays={setNumDays}
        displayWeeks={[4, 8, 16]}
      >
        <Stack spacing={4}>
          {reportQuery.isPending && reportQuery.isFetching && (
            <ActivityIndicator />
          )}
          {reportQuery.isError && <ErrorIndicator />}
          {reportQuery.data &&
            (reportQuery.data.metrics.length === 0 ? (
              <Typography variant="h4"></Typography>
            ) : (
              <VgtTrendsChart
                data={transformData(reportQuery.data.metrics)}
                endDate={endDate}
              />
            ))}
        </Stack>
      </WeekRangeSelect>
    </GamingReportsHeader>
  )
}
